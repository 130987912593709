import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, Inject, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { throwError, Observable } from "rxjs";
import { catchError,startWith, map } from "rxjs/operators";
import { Tags } from "../video-editing-model/video-editing-model.component";
import { VideoService } from "../video-editing-model/video.service";

@Component({
  selector: "app-upload-extarcurricular-video",
  templateUrl: "./upload-extarcurricular-video.component.html",
  styleUrls: ["./upload-extarcurricular-video.component.css"],
})
export class UploadExtarcurricularVideoComponent implements OnInit {
  @ViewChild('videoIdInput') videoIdInput: ElementRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  posterUrl: string;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  Tagging: Tags[] = [{ name: "Demo" }];
  classData: any;
  CourseData: string[] = ["Academic Course", "Extra-Curricular", "Free-Videos"];
  playlistFalg: boolean = false;
  linkPage: boolean = false;
  productLines: any;
  videoSrc: any;
  showVideoFlag: boolean;
  videoLinks: any = [];
  languageList: any;
  productGroupData: any;
  subjectData: any;
  productData: any;
  prodFlag: boolean;
  chapterData: any;
  videoUpdateFlag: boolean;
  extraUpdateVideoData: any;
  idExtraProductGroup: any;
  idOfflineVideoCourse: number;
  generatedValue: string;
  generatedValueFlag: boolean = false;
  idProduct: any;
  idSubject: any;
  pdfURL: any;
  progress: number;
  langPdfURL: any;
  selectVideoType: any=null;
  videoEnLink: any=null;
  uploadedVideoUrl:any=null;
  file:any;
  formData:any;
  videoId:any=null;
  folderIndex:any=null;
  rootBoardFolders:any=null;
  selectedBoardName:any=null;
  gradeFolders:any=null;
  subjectFolders:any=null;
  selectedGradeName:any=null;
  selectedSubFolderName:any=String;
  parentFolder:any='Folders';
  filteredOptions: Observable<Headings[]>;
  headings:any=null;
  folderID:any=null;
  isVideoFileChanged:boolean=false;
  isVideoIdChanged:boolean=false;
  radioOptions = [
    { value: 'id', label: 'Video ID' },
    { value: 'file', label: 'File Upload' },
  ]
  constructor(
    private _formBuilder: FormBuilder,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serv: VideoService,
    private dialogRef: MatDialogRef<UploadExtarcurricularVideoComponent>,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {

    if (this.data.offData != null) {
       console.log(this.data);
       

      this.videoUpdateFlag = true;

      if (this.data.linkUpload) {
        this.linkPage = true;
      }

      this.extraUpdateVideoData = this.data.offData;
      this.idExtraProductGroup  = this.data.idProductGroup;
      this.idOfflineVideoCourse = this.data.idOfflineVideoCourse;

      this.firstFormGroup = this._formBuilder.group({
        topic: [this.extraUpdateVideoData.topic, Validators.required],
        description: [this.extraUpdateVideoData.description],
        productLine: ["6"],
        productGroup: [this.data.idProductGroup, ],
        product: [null],
        chapter: [this.extraUpdateVideoData.idSubjectChapter, Validators.required],
        videoSeqNum: [this.extraUpdateVideoData.videoSeqNumber, Validators.required],
       
      });

      if(this.extraUpdateVideoData.videoEnLink){
        setTimeout(()=>{
          this.selectedOption('id')
        },1000)
      }
      let checkValue = "";
      if(this.extraUpdateVideoData.videoEnLink){
        checkValue = "id";
      }
      this.secondFormGroup = this._formBuilder.group({
        videoId:[this.extraUpdateVideoData.videoEnLink, Validators.required],
        heading: [this.extraUpdateVideoData.heading, [Validators.required, Validators.maxLength(100)]],
        question: [this.extraUpdateVideoData.question, [Validators.required,Validators.maxLength(500)]],
        answer: [this.extraUpdateVideoData.answer, [Validators.required, Validators.maxLength(1500)]], 
        pdfURL:[""],
        videoUrl: [""],
        selectVideoType:[checkValue],
        folder:[null],       
      });

      this.pdfURL=this.extraUpdateVideoData.pdfUrl;

      this.thirdFormGroup = this._formBuilder.group({
        language: ["", Validators.required],
        videoId: [""], //["", Validators.required],
        heading: ["", Validators.required],
        question: ["", Validators.required],
        answer: ["", Validators.required],  
        insertText: [""],
        langPdf:[""]

      });
        
      this.videoLinks = this.extraUpdateVideoData.videoLinks;
      this.getProductGroup(6);
      this.getProduct();
      let event = {
        idSubject:this.extraUpdateVideoData.idSubject
      }
      this.getSubjectsChapter(event);
      this.prodFlag =true;
      this.playlistFalg = true;
      this.idProduct = this.extraUpdateVideoData.idProduct;
      this.idSubject = this.extraUpdateVideoData.idSubject;
      this.videoLinks = this.extraUpdateVideoData.videoLinks;
    
    }
    else {


      this.firstFormGroup = this._formBuilder.group({
        topic: [null, Validators.required],
        description: [null],
        productLine: ["6"],
        productGroup: [-1],
        product: [null],
        chapter: [null, Validators.required],
        videoSeqNum: [null, Validators.required],
       
      });


  
      this.secondFormGroup = this._formBuilder.group({
        videoId: [""], //["", Validators.required],
        heading: ["", [Validators.required, Validators.maxLength(100)]],
        question: ["", [Validators.required,Validators.maxLength(500)]],
        answer: ["", [Validators.required, Validators.maxLength(1500)]],
        pdfURL:[""],
        videoUrl: [""],
        selectVideoType:[""],       
      });

      this.thirdFormGroup = this._formBuilder.group({
        language: ["", Validators.required],
        videoId: [""], //["", Validators.required],
        heading: ["", Validators.required],
        question: ["", Validators.required],
        answer: ["", Validators.required],  
        insertText: [""],
        langPdf:[""]

      });

  
      if (this.data != null) {
        if (this.data.linkUpload) {
          this.linkPage = true;
        }
      }
    }
    
  }

  onVideoFileChange() {
    this.isVideoFileChanged = true;
    // Update validation manually (optional)
    // this.secondFormGroup.updateValueAndValidity();
  }
  shouldFolderBeRequired(): boolean {
    return this.isVideoIdChanged || this.isVideoFileChanged;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.Tagging.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(fruit: Tags): void {
    const index = this.Tagging.indexOf(fruit);

    if (index >= 0) {
      this.Tagging.splice(index, 1);
    }
  }

  ngOnInit() {
    this.getAllSubjects();
    this.getAllLanguage();
    this.getProductGroup(6);
    this.getProduct();
    this.getFolders('root')
    this.filteredOptions = this.secondFormGroup.controls["heading"].valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.headings.slice())
    );
  }
  getHeadings(idChapter)
{
  if ( this.idProduct == null)
  {
    this.toaster.warning("no product selected");
    return null;
  }
  this.headings  = [];
  this.http.get("/api/v1/offlineCourse/headings?idProduct="+this.idProduct+"&idSubjectChapter="+idChapter).subscribe(
    (res: any) => {
     this.headings = res.data;
     console.log(this.headings); 
    },
    (err: any) => {
      this.headings = [];
    }
  );

}

removeVideo(){
  console.log(this.secondFormGroup.controls)
  this.secondFormGroup.controls["videoUrl"].setValue("");
  this.uploadedVideoUrl = null;
  this.secondFormGroup.controls["videoUrl"].reset();
  let path = new URL(this.uploadedVideoUrl).pathname;
let extractedPath = path.match(/vdocipher\/.*/)[0];  // Regex to match "vdocipher" and everything after
console.log(extractedPath); 
  this.http.delete ('/api/v1/offlineCourse/delete-video?key='+extractedPath).subscribe(
    (res:any)=>{
      console.log('Response', res)
    }
  )
  console.log('dsadad', this.secondFormGroup.controls["videoUrl"])
}

private _filter(name: string): Headings[] {
  const filterValue = name.toLowerCase();

  return this.headings.filter(option => option.heading.toLowerCase().includes(filterValue));
}

  selectedOption (vl) {
    console.log('vl', vl)
    this.selectVideoType = vl
    if (vl === 'id') {setTimeout(() => {
      this.videoIdInput.nativeElement.focus();
    }, 100);
    }
  }

  getProductGroup(idProductLine) {
    this.serv
      .getAllProductGroupByProductLineId(idProductLine)
      .subscribe((res: any = []) => {
        if (res.data != null) {
          this.productGroupData = res.data;
        }
      });
  }

  getAllSubjects() {
    this.serv.getAllECASubjects().subscribe((res: any = []) => {
      if (res.data != null) {
        this.subjectData = res.data;
      }
    });
  }
  uploadVideo(videoInput:any){
    this.onVideoFileChange();
    this.uploadedVideoUrl=null;
    this.file = (videoInput.target as HTMLInputElement).files[0];
    this.formData= new FormData();
    this.formData.append("video", this.file ?? "");
    this.formData.forEach((value, key) => {
      console.log("formdata "+key, value);
    });    
    this.http.post('/api/v1/offlineCourse/upload-video', this.formData, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
          
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
              console.log('Video Uploaded Successfully!', event.body);
              if (event.body.statusCode === 201 || event.body.statusCode === 200) {
                this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
                this.uploadedVideoUrl= event.body.data;
                // window.location.reload();
              }
              else {
                this.toaster.error(event.body.message, "Error");
              }
            // setTimeout(() => {
            //   this.progress = 0;
            // }, 1500);
    
          }
        })
  }
  getFolders(folder) {
    console.log('folder', folder)
    let folderId,i
    
    if (folder === 'root')
      {
        folderId = 'root'
        this.folderIndex=0
      }else {
        folderId = folder.id
        this.folderIndex=this.folderIndex+1
        
      }
      console.log('folderIndex', this.folderIndex)
  
     
    this.http.get('api/v1/offlineCourse/get-vdocipher-folders?folderId='+folderId).subscribe(
      (res:any)=> {
        if(this.folderIndex===0){
          //this.rootBoardFolders = res.data.folderList
          this.rootBoardFolders = res.data.folderList.filter(item => item.name == 'Building_Legends');
          this.isVideoFileChanged = false
        }else if (this.folderIndex===1){
          this.selectedBoardName = folder.name
          this.gradeFolders = res.data.folderList
          console.log('gradeFolders',this.gradeFolders)
        }else if(this.folderIndex===2){
          this.subjectFolders = res.data.folderList
          this.selectedGradeName =folder.name
          console.log('subjectFolders',this.subjectFolders)
  
        }
  
      }
    )
  }
  getSubFolders(selectedFolder){
    console.log('video id', selectedFolder)
    if(selectedFolder.foldersCount>0)
      {
        this.getFolders(selectedFolder)
        this.selectedSubFolderName = selectedFolder.name
        this.folderID = selectedFolder.id 
       
      }
  
  }
  createVideo() {
    console.log(this.folderID)
    if(this.isVideoFileChanged && this.folderID==null){
      this.secondFormGroup.get('folder')?.setValidators([Validators.required]);
      this.secondFormGroup.get('folder')?.updateValueAndValidity();
      this.toaster.error('Please select Folder on video info.');
      return;
    }
    console.log('this.secondFormGroup.controls', this.folderID)
    if (this.uploadedVideoUrl == null && this.secondFormGroup.controls["videoId"].value == '') {
      this.toaster.error('Please provide either a video URL or a video ID.');
      return; // Exit the function if neither URL is provided
    }
    if (this.videoUpdateFlag) {
      if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
        let request = {
          idProduct:this.idProduct,
          topic:this.firstFormGroup.controls["topic"].value ,
          description:this.firstFormGroup.controls["description"].value ,
          idProductGroup:-1 ,
          videoEnLink:this.secondFormGroup.controls["videoId"].value ,
          idSubjectChapter:this.firstFormGroup.controls["chapter"].value ,
          idSubject:  this.idSubject,
          videoSeqNumber:this.firstFormGroup.controls["videoSeqNum"].value ,
          idClassStandard:4 ,
          heading:this.secondFormGroup.controls["heading"].value ,
          question:this.secondFormGroup.controls["question"].value ,
          answer: this.secondFormGroup.controls["answer"].value,
          videoLinks: [],  
          videoUrl:this.uploadedVideoUrl,
          pdfUrl:this.pdfURL,
          folderId: this.folderID,
        };

        this.serv.updateVideo(request, this.idOfflineVideoCourse).subscribe(
          (res: any = []) => {
            if (res.data != null) {
              this.dialogRef.close({ data: res.data });
            }
          },
          (error) => {
            this.toaster.error(error.error.message, "An Error Occured!");
          }
        );
      }
    } else {
      if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
        
        let request= {
          idProduct:this.idProduct,
          topic:this.firstFormGroup.controls["topic"].value ,
          description:this.firstFormGroup.controls["description"].value ,
          idProductGroup:-1 ,
          videoEnLink:this.secondFormGroup.controls["videoId"].value ,
          idSubjectChapter:this.firstFormGroup.controls["chapter"].value ,
          idSubject:  this.idSubject,
          videoSeqNumber:this.firstFormGroup.controls["videoSeqNum"].value ,
          idClassStandard:4 ,
          heading:this.secondFormGroup.controls["heading"].value ,
          question:this.secondFormGroup.controls["question"].value ,
          answer: this.secondFormGroup.controls["answer"].value,
          videoLinks: [],
          videoUrl:this.uploadedVideoUrl,
          pdfUrl:this.pdfURL,
          folderId: this.folderID,
      }
        this.serv.createVideo(request).subscribe(
          (res: any = []) => {
            if (res.data != null) {
              this.dialogRef.close({ data: res.data });
            }
          },
          (error) => {
            this.toaster.error(error.error.message, "An Error Occured!");
          }
        );
      }
    }
  }

  getVideoStreaminginfo(): any {
    let videoId = this.secondFormGroup.controls["videoId"].value;
    this.showVideoFlag = false;
    if (videoId != null && videoId != "") {
      this.serv.getVideoStreaminginfo(videoId).subscribe(
        (res: any) => {
          if (res.data != null) {
            this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              "https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=" +
                res.data.otp +
                "&playbackInfo=" +
                res.data.playbackInfo
            );
            this.showVideoFlag = true;
            return true;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
          this.firstFormGroup.controls["videoId"].setErrors({ required: true });
        }
      );
    }
  }

  getAllLanguage() {
    this.serv.getAllLanguages().subscribe((res: any = []) => {
      if (res.data != null) {
        this.languageList = res.data;
      }
    });
  }

  addVideoLink() {

    if(this.thirdFormGroup.valid){
    let lang = this.thirdFormGroup.controls["language"].value;
    let vid = this.thirdFormGroup.controls["videoId"].value;
    let head =this.thirdFormGroup.controls["heading"].value;
    let ques = this.thirdFormGroup.controls["question"].value;
    let ans = this.thirdFormGroup.controls["answer"].value;

    if (lang == null || lang == "") {
      this.toaster.error("Please Select Valid Language", "Valid Data Required");
      return;
    }

    if (vid == null || vid == "") {
      this.toaster.error("Video Id Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (head == null || head == "") {
      this.toaster.error("Heading Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (ques == null || ques == "") {
      this.toaster.error("Question Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (ans == null || ans == "") {
      this.toaster.error("Answer Id Cannot Be Empty!", "Valid Data Required");
      return;
    }

    // check combinations here
    for (let i = 0; i < this.videoLinks.length; i++) {
      if (this.videoLinks[i].language === lang) {
        this.toaster.error(
          "Duplicate Language Data found!",
          "Valid Data Required"
        );
        return;
      }
    }

    this.serv.getVideoStreaminginfo(vid).subscribe(
      (res: any) => {
        if (res.data != null) {
          // this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp="+res.data.otp+"&playbackInfo="+res.data.playbackInfo);
          // this.showVideoFlag=true;
          let object = {
            language: lang,
            link: vid,
            heading: head,
            question: ques,
            answer : ans ,
            pdfURL: this.langPdfURL
          };
          this.videoLinks.push(object);
          this.thirdFormGroup.reset();
          this.thirdFormGroup.markAsPristine();
          this.thirdFormGroup.markAsUntouched();
          this.thirdFormGroup.clearValidators();
          this.thirdFormGroup.updateValueAndValidity();
          this.langPdfURL = null;

        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
        this.thirdFormGroup.controls['videoId'].setErrors({ required: true });
      }
    );
    }

    else{
      return ;
    }
  }

  removeSub(subChip, index) {
    this.videoLinks.splice(index, 1);
  }

  getProduct() {
  
    
      this.productData = null;
      this.prodFlag = false;
      this.chapterData = null;
      this.playlistFalg = false;
      this.serv.getAllECAProduct().subscribe(
        (res: any) => {

          if (res.data != null) {
            this.productData = res.data;
            this.prodFlag = true;

            if (this.videoUpdateFlag)
            {
              for (let i = 0; i < this.productData.length ; i++)
              {
                   
                if ( this.productData[i].idProduct == this.extraUpdateVideoData.idProduct ) 
                {
                  this.firstFormGroup.controls['product'].setValue(this.productData[i]);
                }

              }

            }
          }
        },
        (error) => {
          console.warn(error);
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    
  }

  getSubjectsChapter(event) {
    if (event != null && event != "") {
      this.chapterData = null;
      console.log(event);
      this.idProduct = event.idProduct;
      this.idSubject = event.idSubject;
      // id classStandard value is hardcoded for NA
      this.serv.getallChapter(event.idSubject,4,4,6).subscribe(
        (res: any) => {
          if (res.data != null && res.statusCode == "200") {
            this.playlistFalg = true;
            this.chapterData = res.data;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }


  generateStringValue()
  { 
   let heading =  this.secondFormGroup.controls["heading"].value;
   let question =  this.secondFormGroup.controls["question"].value;
   let answer =  this.secondFormGroup.controls["answer"].value;

    if ( (heading != null && heading !="") && (question != null && question !="") 
    && (answer != null && answer !="") )
    { 
      this.generatedValue =  heading +":::"
      + question +":::"
      + answer ;
      this.generatedValueFlag = true;
    }
   
  }

  insertText()
  { 
   let text = this.thirdFormGroup.controls["insertText"].value;

   if(this.insertText !=null){
     let insertValue:any= text.split(":::");
     let heading :any = insertValue[0];
     let question:any = insertValue[1];
     let answer :any = insertValue[2];  
    this.thirdFormGroup.controls["heading"].setValue(heading);
    this.thirdFormGroup.controls["question"].setValue(question);
    this.thirdFormGroup.controls["answer"].setValue(answer);
    
   }
   
  }
  onNavigateTranslator(){
    window.open("https://translate.google.co.in/", "_blank");
}


uploadPdf(fileInput:any){

  this.pdfURL=null;
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("liveClassPdf", file);
    console.log("formdata",formData);
    this.http.post('/api/v1/liveClass/uploadPdf', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.pdfURL= event.body.data;
              console.log("pdf",this.pdfURL);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
      
}

errorMgmt(error: HttpErrorResponse) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

removepdf() {
  this.pdfURL = null;
  this.secondFormGroup.controls["pdfURL"].setValue("");
  this.secondFormGroup.controls["pdfURL"].reset();
}

uploadLanguagePdf(fileInput:any){

  this.langPdfURL=null;
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("liveClassPdf", file);
    console.log("formdata",formData);
    this.http.post('/api/v1/liveClass/uploadPdf', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.langPdfURL= event.body.data;
              console.log("Laanguage pdf",this.langPdfURL);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
      
}

}

export interface Headings 
{
  heading:string;
}
