<div class="page-content page-container" id="page-content">
    <div class="padding">
        <div class="row container d-flex justify-content-center">
<div class="col-xl-6 col-md-12">
                                                <div class="card user-card-full">
                                                    <div class="row m-l-0 m-r-0">
                                                        <div class="col-sm-4 bg-c-lite-green user-profile">
                                                            <div class="card-block text-center text-white">
                                                                <div class="m-b-25">
                                                                    <img [src]="data.userPicURL || '../../assets/images/users/1.jpg'" class="img-radius " alt="User-Profile-Image">
                                                                </div>
                                                                <h6 class="f-w-600">{{data.name}}</h6>
                                                                <p>{{data.userRole}}</p>
                                                                <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                                                                <br>
                                                                <br>
                                                                <mat-slide-toggle
                                                                class="example-margin"
                                                                [color]="'red'"
                                                                 [(checked)]="data.isActive"
                                                                 (change)="updateActiveStatus($event.source.checked)"
                                                                 >
                                                                 &nbsp; &nbsp; &nbsp; Activate User
                                                               </mat-slide-toggle>
                                                                 
                                                                

                                                                <br>

                                                                <mat-slide-toggle
                                                                *ngIf="data.userRole=='Student'"
                                                                class="example-margin"
                                                                [color]="'black'"
                                                                 [checked]="!data.studentEditFlag" [disabled]="!data.studentEditFlag" (change)="updateStudentFlag()">
                                                                 
                                                                 &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; Allow Edit
                                                               </mat-slide-toggle>

                                                                
                                                                <hr>
                                                                <h6>User Status: {{data.isActive ? "Active" : "Inactive"}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <div class="card-block">
                                                                <h6 class="m-b-20 p-b-5 b-b-default f-w-600">User Information</h6>
                                                                <div class="row">
                                                                    <div class="col-sm-6" >
                                                                        <p *ngIf="!editClassStandard" class="m-b-10 f-w-600">Class Standard</p>
                                                                        <div *ngIf="!editClassStandard" style="display: inline-flex; align-items: center;">
                                                                            <h6 class="text-muted f-w-400">{{data.classStandard}}</h6><mat-icon (click)="editClass()" style="font-size: 15px; margin-left: 10px; cursor:pointer">edit</mat-icon>

                                                                        </div>
                                                                        <div *ngIf="editClassStandard"  style="align-items: center;">
                                                                            <mat-form-field>
                                                                            <mat-label>Select Class Standard</mat-label>
                                                                            <mat-select
                                                                              required
                                                                              (selectionChange)="setEditData($event.value,'idClassStandard')"
                                                          
                                                                            >
                                                                              <mat-option
                                                                                *ngFor="let class of classData"
                                                                                [value]="class.idClassStandard"
                                                                              >
                                                                                {{ class.classStandadName }}
                                                                              </mat-option>
                                                                            </mat-select>
                                                                            </mat-form-field>
                                                                            <!-- <input matInput appearance="fill" class="class-input" placeholder="Enter Class Standard" [(ngModel)]="classStd" type="text">
                                                                            <button class="btn btn-sm btn-primary ml-2" (click)="submitClass()">Submit</button> -->
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Syllabus</p>
                                                                        <div *ngIf="!editSyllabus" style="display: inline-flex; align-items: center;">
                                                                            <h6 class="text-muted f-w-400">{{data.syllabusType}}</h6> <mat-icon (click)="editSyllabusFun()" style="font-size: 15px; margin-left: 10px; cursor:pointer">edit</mat-icon>
                                                                        </div>
                                                                        <div *ngIf="editSyllabus"  style="align-items: center;">
                                                                            <mat-form-field>
                                                                            <mat-label>Select Syllabus</mat-label>
                                                                            <mat-select
                                                                              required
                                                                              (selectionChange)="setEditData($event.value, 'idSyllabus')"
                                                                            >
                                                                            <mat-option
                                                                            *ngFor="let data of syllabusData; let j = index"
                                                                            [value]="data.idSyllabus"
                                                                            >{{ data.syllabusName }}</mat-option>
                                                                            </mat-select>
                                                                            </mat-form-field>
                                                                            <!-- <input matInput appearance="fill" class="class-input" placeholder="Enter Class Standard" [(ngModel)]="classStd" type="text">
                                                                            <button class="btn btn-sm btn-primary ml-2" (click)="submitClass()">Submit</button> -->
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">State</p>
                                                                        <div *ngIf="!editState" style="display: inline-flex; align-items: center;">
                                                                            <h6 class="text-muted f-w-400">{{data.state}}</h6> <mat-icon (click)="editStateFun()" style="font-size: 15px; margin-left: 10px; cursor:pointer">edit</mat-icon>
                                                                        </div>
                                                                        <div *ngIf="editState"  style="align-items: center;">
                                                                            <mat-form-field>
                                                                            <mat-label>Select State</mat-label>
                                                                            <mat-select
                                                                              required
                                                                              (selectionChange)="setEditData($event.value,'idState')"
                                                                            >
                                                                            <mat-option
                                                                            *ngFor="let data of statesData; let j = index"
                                                                            [value]="data.idState"
                                                                            >{{ data.state }}</mat-option
                                                                          >
                                                                            </mat-select>
                                                                            </mat-form-field>
                                                                            <!-- <input matInput appearance="fill" class="class-input" placeholder="Enter Class Standard" [(ngModel)]="classStd" type="text">
                                                                            <button class="btn btn-sm btn-primary ml-2" (click)="submitClass()">Submit</button> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Secondary Language</p>
                                                                        <div *ngIf="!editLanguage" style="display: inline-flex; align-items: center;">
                                                                            <h6 class="text-muted f-w-400">{{data.secondaryLanguage}}</h6>
                                                                            <mat-icon (click)="editLanguageFun()" style="font-size: 15px; margin-left: 10px; cursor:pointer">edit</mat-icon>
                                                                        </div>
                                                                        <div *ngIf="editLanguage"  style="align-items: center;">
                                                                            <mat-form-field>
                                                                            <mat-label>Select State</mat-label>
                                                                            <mat-select required (selectionChange)="setEditData($event.value,'idLanguage')">
                                                                            <mat-option *ngFor="let language of languageData; let j = index" [value]="language.idLanguage">
                                                                                {{ language.language }}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <h6 *ngIf="data.userRole == 'Student'" class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Additional Information</h6>
                                                                <div class="row" *ngIf="data.userRole == 'Student'">
                                                                    <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Subscription</p>
                                                                        <h6 class="text-muted f-w-400">{{ data.subscribed ? 'Premium' : 'Basic'}}</h6>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <p class="m-b-10 f-w-600">Duration</p>
                                                                        <div *ngIf="data.subscriptionStartDate != null && data.subscriptionEndDate != null ; else elseBlock">
                                                                            <h6 class="text-muted f-w-400"> FROM :&nbsp;{{ data.subscriptionStartDate | date:'mediumDate' }} <br> TILL :&nbsp;{{data.subscriptionEndDate | date:'mediumDate'}} </h6>
                                                                          </div>
                                                                          
                                                                          <ng-template #elseBlock>
                                                                            <h6>Lifetime</h6>
                                                                          </ng-template>
                                                                       
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="data.remarks" class="alert alert-info" role="alert">
                                                                   {{data.remarks}}
                                                                  </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             </div>
                                                </div>
                                            </div>