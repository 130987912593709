import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CreateQuizService {
  public baseUrl: any = "/api/v1/subject";
  httpOptions: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastrService
  ) {
    let accesstoken = localStorage.getItem("accessToken");
    if (
      accesstoken != null &&
      accesstoken != "" &&
      typeof accesstoken != undefined
    ) {
      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
          "No-Auth": "True",
        }),
      };
    }
  }

  getClassStandard() {
    return this.http.get("/api/v1/classStandard/", this.httpOptions);
  }

  getSubjects() {
    return this.http.get("/api/v1/subject/list", this.httpOptions);
  }

  getAcademicSubjects(idClassStandard, idSyllabus, idState) {
    return this.http.get("/api/v1/subject/academic/list?idClassStandard=" + idClassStandard +"&idSyllabus="+idSyllabus+"&idState="+idState, this.httpOptions);
  }
  getVctQuizProducts(idClassStandard,idSyllabus,idState){
    return this.http.get("api/v1/Product/vct?idSyllabus=" + idSyllabus +"&idClassStandard=" + idClassStandard +"&idState="+idState)


  }
  getChaptersOnSectSubject(idSubject, idClassStandard, idSyllabus, idState) {
    return this.http.get(
      "/api/v1/subject/" +
        idSubject +
        "/chapters?idClassStandard=" +
        idClassStandard +
        "&idSyllabus=" +
        idSyllabus +
        "&idState=" +
        idState,
      this.httpOptions
    );
  }

  createAcacdemicQuiz(
    idClassStandard,
    idProductLine,
    idSyllabus,
    idState,
    udm
  ) {
    return this.http.post(
      "/api/v1/quiz/createQuiz/academic/" +
        idClassStandard +
        "/" +
        idProductLine +
        "/?idSyllabus=" +
        idSyllabus +
        "&idState=" +
        idState,
      udm,
      this.httpOptions
    );
  }

  
  createExamPreparationQuiz(
    idClassStandard,
    idProductLine,
    idSubject,
    category,
    idSyllabus,
    idState,
    udm
  ) {
    return this.http.post(
      "/api/v1/quiz/create-exam-preparation?idClassStandard=" +idClassStandard+
        "&idProductLine=" +idProductLine+
        "&idSyllabus=" +
        idSyllabus +
        "&idState=" +

        idState+"&idSubject="+idSubject+"&category="+category,
      udm,
      this.httpOptions
    );
  }

  createQuestionandAnswers(idQuiz, udm) {
    return this.http.post(
      "/api/v1/quiz/" + idQuiz + "/question/create",
      udm,
      this.httpOptions
    );
  }

  updateChapterFlag (chapterData: any) {
    return this.http.put(
      "/api/v1/subject/update-chapter-status?idSubjectChapter="+ chapterData.idSubjectChapter +"&status="+!chapterData.activeFlag,
      this.httpOptions
    );
  }

 updateQuestionandAnswers(idQuizQuestion, udm) {
    return this.http.put(
      "/api/v1/quiz/question/"+ idQuizQuestion +"/update",
      udm,
      this.httpOptions
    );
  }

  getQuestionsList(idQuiz) {
    return this.http.get(
      "/api/v1/quiz/" + idQuiz + "/questions",
      this.httpOptions
    );
  }

  deleteQuestion(idQuestion) {
    return this.http.delete(
      "/api/v1/quiz/question/" + idQuestion + "/delete",
      this.httpOptions
    );
  }

  getAllsyllabus() {
    return this.http.get("/api/v1/lead/syllabusLists");
  }

  getOfflineVideoCourses(
    idSubject,
    idSubjectChapter,
    idSyllabus,
    idClassStandard,
    idState
  ) {
    return this.http.get(
      "/api/v1/offlineCourse/getOfflineVideoCourseBasedOnproduct/" +
        idSubject +
        "/" +
        idSubjectChapter +
        "/" +
        idSyllabus +
        "/" +
        idClassStandard +
        "/" +
        idState,
      this.httpOptions
    );
  }

  getAllState() {
    return this.http.get("/api/v1/auth/statesList", this.httpOptions);
  }

  saveSubjectChapters(data) {
    return this.http.post("/api/v1/subject/chapter", data, this.httpOptions);
  }

  updateSubjectChapters(data) {
    return this.http.put("/api/v1/subject/chapter", data, this.httpOptions);
  }
  getAllEcaSubjects() {
    return this.http.get("/api/v1/subject/eca", this.httpOptions);
  }
}
