import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";

@Injectable({
  providedIn: "root",
})

export class LandingPageService {
    public baseUrl: any = "/api/v1/";
    httpOptions: any;
  
    constructor(
      private http: HttpClient,
      private router: Router,
      private toaster: ToastrService
    ) {
      let accesstoken = localStorage.getItem("accessToken");
      if (
        accesstoken != null &&
        accesstoken != "" &&
        typeof accesstoken != undefined
      ) {
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
            "No-Auth": "True",
          }),
        };
      }
    }



    getAllTeachers(idSubject,idSyllabus,idClassStandard,category,pageNumber) 
    {
        return this.http.get("/api/v1/auth/fetch-teachers?idSubject="+idSubject+"&idSyllabus="+idSyllabus+"&idClassStandard="+idClassStandard+"&category="+category+"&pageNumber="+pageNumber,this.httpOptions);
    }

    getAcademicTeachers(category) 
    {
        return this.http.get("api/v1/teacher/getteacherByCategory?category="+category,this.httpOptions);
    }
    getLandingAcademicTeachers(category) 
    {
        return this.http.get("api/v1/teacher/teacher-by-category?category="+category,this.httpOptions);
    }
   
    getAllSubjects() 
    {
        return this.http.get("/api/v1/subject/list",this.httpOptions);
    }

    getAllSubjectsAcademic(idClassStandard,idSyllabus) 
    {
        return this.http.get("/api/v1/subject/academic?idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus,this.httpOptions);
    }

    getUpcomingLiveClassDate()
    {
      return this.http.get("api/v1/liveClass/future-class/",this.httpOptions);
    }

    getLiveClassGraphData(idClassStandard,userSurId){
      return this.http.get("api/v1/liveClass/graph-result?idClassStandard="+idClassStandard+"&userSurId="+userSurId,this.httpOptions);
    }

    getAcademic(idClassStandard,idSyllabus)
    {
      return this.http.get("api/v1/subject/academic?idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus,this.httpOptions);
    }
    getExtraCurricular()
    {
      return this.http.get("api/v1/Product/6/product-line", this.httpOptions);
    }
    getClassStandard(idStudent)
    {
      return this.http.get("api/v1/student/class-standard/"+idStudent);
    }

    languageSelect(id) {   
      this.router.navigate(["/subject"], { state: { idSubject: id } });
      localStorage.setItem('_idsss', id);
    }

    //
    getMainAcademicVideo(idLiveClassCategory){
      return this.http.get("api/v1/liveClass/academic-home?idLiveClassCategory="+idLiveClassCategory,this.httpOptions);
    }

    getAllAcademicClasses(idLiveClassCategory){
      return this.http.get("api/v1/liveClass/all-academic-live-class?idLiveClassCategory="+idLiveClassCategory,this.httpOptions)
    }
    
    getAcademicClasses(idLiveClassCategory){
      return this.http.get("api/v1/liveClass/academic-live-class?idLiveClassCategory="+idLiveClassCategory,this.httpOptions)
    }
      
    getAllLiveClassesByCategory(idClassStandard, idLiveClassCategory){
      return this.http.get("api/v1/liveClass/allcategory-live-classes?idClassStandard="+idClassStandard+"&idLiveClassCategory="+idLiveClassCategory,this.httpOptions)
    }
    
    getAllBroadCastedVideo(idLanguage, idSubject,pageNumber,idClassStandard,idSyllabus,idState,idLiveClassCategory){
      return this.http.get("api/v1/liveClass/all-broadcasted-class?idLanguage="+idLanguage+"&idSubject="+idSubject+"&pageNumber="+pageNumber+"&idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus+"&idState="+idState+"&idLiveClassCategory="+idLiveClassCategory,this.httpOptions)
    }

    getBroadcastedVideos(IdClassStandards, idLanguage, idSubject,pageNumber,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/all-broadcast?IdClassStandards="+IdClassStandards+"&idLanguage="+idLanguage+"&idSubject="+idSubject+"&pageNumber="+pageNumber+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions)
    }

      
    getExtraCurSubjects(){
      return this.http.get("api/v1/subject/eca",this.httpOptions)
    }
  
    searchAcademicTeacher(keyword,pageNumber){
      return this.http.get("api/v1/teacher/search/Academic?query="+keyword+"&pageNumber="+pageNumber);
    }

    getAllECASubjects() {
      return this.http.get("/api/v1/subject/eca");
    }

    searchECATeacher(keyword,pageNumber){
      return this.http.get("api/v1/teacher/search/EXTRA_CUR?query="+keyword+"&pageNumber="+pageNumber);
    }

    getHomePageVideo(){
      return this.http.get("api/v1/liveClass/fetch-liveclass-homepage",this.httpOptions);
    }

    getAllLiveClasses() {
          return this.http.get("/api/v1/liveClass/fetch-allclass");
    }

    getAllLiveClassByClassstandard(classStandards,idSyllabus,idState) { 
      return this.http.get("/api/v1/liveClass/fetch-allclass-ByIdClassStandard?IdClassStandards="+classStandards+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions);
    }

    getMainVideoExtraVideo(idLiveClassCategory){
      return this.http.get("api/v1/liveClass/extra-home?idLiveClassCategory="+idLiveClassCategory, this.httpOptions);
    }

    getAllAcademicLive(idLiveClassCategory, idLanguage,idSubject){
      return this.http.get("api/v1/liveClass/all-academic-live?idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage+"&idSubject="+idSubject,this.httpOptions)

    }

    getAllAcademicUpcoming(idLiveClassCategory, idLanguage, idSubject){
      return this.http.get("api/v1/liveClass/all-academic-upcoming?idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage+"&idSubject="+idSubject,this.httpOptions)
    }

    beforeLoginAcademicLiveClasses(idLiveClassCategory, idLanguage,idSubject,idClassStandard,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/before-login-academic-live?idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage+"&idSubject="+idSubject+"&idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions)

    }
    BeforeLoginAcademicUpcomingClasses(idLiveClassCategory,idLanguage,idClassStandard,idSubject,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/before-login-academic-upcoming?idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage+"&idClassStandard="+idClassStandard+"&idSubject="+idSubject+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions)
    }

    getAllLiveClassByClass(idClassStandard, idLiveClassCategory){
      return this.http.get("api/v1/liveClass/all-live-class-by-class?idClassStandard="+idClassStandard+"&idLiveClassCategory="+idLiveClassCategory,this.httpOptions)

    }
    getAllUpcomingClassByClass(idClassStandard, idLiveClassCategory){
      return this.http.get("api/v1/liveClass/all-upcoming-class-by-class?idClassStandard="+idClassStandard+"&idLiveClassCategory="+idLiveClassCategory,this.httpOptions)
    }

    getLiveClassMainVideoByClass(idClassStandard, idLiveClassCategory, idLanguage, idSubject,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/live-main-video-by-class?idClassStandard="+idClassStandard+"&idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage+"&idSubject="+idSubject+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions);
    }

    getAllLanguages(){
      return this.http.get("api/v1/auth/languages",this.httpOptions);
    }
    getAllLiveClassByClassAndLanguage(idClassStandard, idLiveClassCategory, idLanguage){
      return this.http.get("api/v1/liveClass/all-live-class-by-class?idClassStandard="+idClassStandard+"&idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage,this.httpOptions)

    }
    getAllUpcomingClassByClassAndLanguage(idClassStandard, idLiveClassCategory,idLanguage){
      return this.http.get("api/v1/liveClass/all-upcoming-class-by-class?idClassStandard="+idClassStandard+"&idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage,this.httpOptions)
    }
    getAllLiveAndUpcomingBySubjectAndClassAndLan(idLiveClassCategory, language,idSubject,idClassStandard,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/live-class-by-sub-and-lan?idLiveClassCategory="+idLiveClassCategory+"&language="+language+"&idSubject="+idSubject+"&idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions)
    }

    otherclassLiveBeforeLogin(idLiveClassCategory,idSubject,idClassStandard,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/otherclass_live_before_login?idLiveClassCategory="+idLiveClassCategory+"&idSubject="+idSubject+"&idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus+"&idState="+idState,this.httpOptions)
    }

    otherclassUpcomingBeforeLogin(idLiveClassCategory,idSubject,idClassStandard,idSyllabus,idState){
      return this.http.get("api/v1/liveClass/otherclass_upcoming_before_login?idLiveClassCategory="+idLiveClassCategory+"&idSubject="+idSubject+"&idClassStandard="+idClassStandard+"&idSllabus="+idSyllabus+"&idState="+idState,this.httpOptions)

    }

    otherclassAfterLoginLive(idLiveClassCategory, idSubject){
      return this.http.get("api/v1/liveClass/after-login-otherclass-live?idLiveClassCategory="+idLiveClassCategory+"&idSubject="+idSubject,this.httpOptions)

    }

    otherclassAfterLoginUpcoming(idLiveClassCategory, idSubject){
      return this.http.get("api/v1/liveClass/after-login-otherclass-upcoming?idLiveClassCategory="+idLiveClassCategory+"&idSubject="+idSubject,this.httpOptions)
    }

    getExtraCurricularSubjects(){
      return this.http.get("api/v1/subject/eca",this.httpOptions);
    }

    getAllExtraCurWebCastedVideos(idLiveClassCategory,idLanguage, idSubject,pageNumber){
      return this.http.get("api/v1/liveClass/all-extra-cur-webcasted?idLiveClassCategory="+idLiveClassCategory+"&idLanguage="+idLanguage+"&idSubject="+idSubject+"&pageNumber="+pageNumber, this.httpOptions)
    }

    getAcademicSubjectsByProduct(idClassStandard,idSyllabus,idState){
      return this.http.get("/api/v1/subject/academic/list?idClassStandard=" + idClassStandard +"&idSyllabus="+idSyllabus+"&idState="+idState, this.httpOptions);
    }

    getSubjectBasedOnCategory(categoryCode){
      return this.http.get("/api/v1/subject/"+categoryCode+"/getSubjects",this.httpOptions);
    }

    otherExtraCurrclassLive(idLiveClassCategory, idSubject){
      return this.http.get("api/v1/liveClass/extracur-otherclass-live?idLiveClassCategory="+idLiveClassCategory+"&idSubject="+idSubject,this.httpOptions)

    }

    otherExtraCurrclassUpcoming(idLiveClassCategory, idSubject){
      return this.http.get("api/v1/liveClass/extracur-otherclass-upcoming?idLiveClassCategory="+idLiveClassCategory+"&idSubject="+idSubject,this.httpOptions)
   
    }
      getHomePageVideoWithCategory(idLiveClassCategory){
      return this.http.get("api/v1/liveClass/fetch-liveclass-homepage?idLiveClassCategory="+idLiveClassCategory,this.httpOptions);
      }

      browseCourseBeforeLogin(){
        return this.http.get("api/v1/subject/browse-course",this.httpOptions);
      }

      getMySocialVideos(pageNumber){
        return this.http.get("api/v1/social-video/my-videos?pageNumber="+pageNumber);
      }

      // getAllSocialVideos(pageNumber){
      //   return this.http.get("api/v1/social-video/?pageNumber="+pageNumber);
      // }
      getSocialVideos(pageNo,size,reqBody){
        return this.http.post("api/v1/social-video/social-video-filter?sort=desc&pageNumber="+pageNo+"&size="+size,reqBody)
      }
      getComments(pageNo,size, reqBody){
        return this.http.post("api/v1/social-video/get-comments?sort=desc&pageNumber="+pageNo+"&size="+size, reqBody)
      }
      updateCommentStatus (id, status){
        return this.http.put ("api/v1/social-video/update-comment-status?idVideoComment="+id+"&isVisible="+status,{})
      }
}

