import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { VideoService } from '../video-editing-model/video.service';
import { ProductGroupService } from '../product-group/product-group.service';
import { LandingPageService } from '../landing/landing.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  editClassStandard:boolean=false;
  editSyllabus:boolean=false;
  editState:boolean=false;
  editLanguage:boolean=false;
  classData:any=null;
  syllabusData: any;
  statesData: any;
  languageData: any;
  constructor(
    public dialogRef: MatDialogRef<UserInfoComponent>,
    private serv: VideoService,
    public toaster: ToastrService, 
    public servProduct: ProductGroupService,
    public servLanding: LandingPageService,
    @Inject(MAT_DIALOG_DATA
    ) 
  public data,public http: HttpClient) { }

  ngOnInit(): void {
  }
  editClass(){
    console.log('edit',this.editClassStandard)
    this.getClassStandardData()
    this.editClassStandard = true
  }
  editSyllabusFun() {
    this.getAllSyllabus();
    this.editSyllabus = true
  }
  editStateFun () {
    this.getAllStates();
    this.editState =  true
  }
  editLanguageFun () {
    this.getAllLanguages();
    this.editLanguage =  true
  }

  getAllSyllabus() {
    this.syllabusData = null;
    this.serv.getAllSyllabus().subscribe((res: any = []) => {
      if (res.data != null) {
        console.log(res.data);
        this.syllabusData = res.data;

      }
    });
  }
  getAllLanguages(){
    this.serv.getAllLanguages().subscribe((res:any)=>{
      if(res.data!=null){
        this.languageData = res.data;
        console.log("Languages", this.languageData);
      }
    })
  }
  getClassStandardData() {
    this.classData = null;

    this.serv.getClassStandardByProductGroup().subscribe(
      (res: any) => {
        if (res.data != null) {
          this.classData = res.data;
         
        }
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
   
  }
  getAllStates() {
    this.statesData = null;
    this.servProduct.getAllStates().subscribe((res: any = []) => {
      if (res.data != null) {
        console.log(res.data);
        this.statesData = res.data;

       
      }
    });
  }
  setEditData(event,payload){
    this.http.get("/api/v1/user/update-class-standard?phoneNumber="+this.data.phoneNumber+"&"+payload+"="+event).subscribe(
      (res:any)=>{
        console.log("edit",res)
        if(res.statusCode==200){
          this.toaster.success(res.message)
          this.dialogRef.close();

        }
      }
    )
  }
  updateActiveStatus(isActive:any)
  {  
    this.http.put('/api/v1/user/update-flag?userSurId=' + this.data.userSurid + '&activeFlag=' + isActive, '').subscribe((res: any) => {
      if (res.data != null) {
    this.dialogRef.close();
      }
    });
    this.dialogRef.close();
  }

  updateStudentFlag() {
    this.http.put('/api/v1/user/update-student-edit-flag?userSurId='+this.data.userSurid , '').subscribe((res: any) => {
      if (res.data != null) {
      }
    })
    this.dialogRef.close();
  }


}
